import { useEffect, useState, useMemo } from 'react';
import { useCookies } from 'react-cookie';
import Carousel from "react-multi-carousel";
import { getWebsiteID } from '/lib/common-functions';
import styles from '/components/Home/Home.module.css';
import ListItemHome from '/components/product/list-item-home';
import Link from 'next/link'
import "react-multi-carousel/lib/styles.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { responsive } from '/lib/services/helpers/responsive';
import { getHomepageProducts } from '/lib/services/helpers/getHomepageProducts';
import style from '/styles/multi-category-home-page.module.scss'

const ItemListHome = ({ product, index, storeid }) => {
	return (
		<ListItemHome
			key={index}
			storeid={storeid}
			id={product.id}
			name={product.name}
			sku={product.sku}
			description={product.short_description.html}
			slug={product.slug}
			image={product.small_image.url ? product.small_image.url: (product.live_home_image.label != 'false' ? product.live_home_image.label: false) }
			url_path={product.url_key}
			url_key={product.url_key}
			price={product.price.regularPrice.amount.value}
			mprice={product.price_range.minimum_price.regular_price.value}
			type={product.type_id}
			conf={product.configurable_options}
			variants={product.variants}
			stock={product.stock_status}
			salableqty={product.salableqty.label}
			media_gallery={product.media_gallery}
			rating_summary={product.rating_summary}
			review_count={product.review_count}
			product_label={product.product_label}
			pwa_oneliner={product.pwa_oneliner}
			lifestyle_flag={product.live_home_image.label != 'false' ? true : false}
		/>
	)
}

const ProductCarousel = (props) => {
	const [products, setProducts] = useState([])
	const [storeid, setStoreid] = useState(1);
	const [cookie, setCookie] = useCookies(["store"]);

	useEffect(() => {
		const response = async () => {
			try {
				var filters = {
					sku: { in: props.productsArr },
				};
				const originalTitle = `${props?.position}-${props.identifier}`
				const result = getHomepageProducts(filters, originalTitle || 1)
				const tmp = await result;
				let tempprod1 = tmp?.data?.products?.items
				let sort_seq = props.product_seq
				sort_seq = sort_seq ? sort_seq.split(",") : null
				tempprod1.map((file, index) => {
					tempprod1[index].sortid = (sort_seq && sort_seq[index] !== "undefined") ? sort_seq[index] : null
				});

				setProducts(tempprod1)
			} catch (error) {
				console.log('error', error);
			}
		};

		response();

	}, [props?.position, props?.url_key]);

	useEffect(() => {
		setStoreid(getWebsiteID(cookie))
	}, [cookie]);

	const filteredAndSortedProducts = useMemo(() => {
		return products?.filter((product) => product.stock_status === 'IN_STOCK').sort((a, b) => a.sortid - b.sortid);
	}, [products]);

	return (
		<div className={`container ${style.productCarousel}`}>
			{(
				<div className={`home_mobile_sec1 ${styles.home_mobile_sec1} ${styles.home_mobile_sec121}`}>
					<div className={styles.journal_colm}>
						<h2 id={props.id} style={{ textTransform: 'uppercase' }}>{props?.title || ''}</h2>
						{props?.url_key ? (
							<Link href={props.url_key} className={styles.icon_see}>

								View All

							</Link>
						) :
							(
								""
							)}
					</div>

					<div>
						<div className={`row ` + styles["row"]}>
							{products && <Carousel
								swipeable={true}
								draggable={false}
								showDots={false}
								responsive={responsive}
								ssr={true} // means to render carousel on server-side.
								infinite={true}
								autoPlaySpeed={3000}
								keyBoardControl={true}
								partialVisible={true}
								removeArrowOnDeviceType={["tablet", "mobile"]}
								additionalTransfrom={-15}
								itemClass={styles.carousel_item_padding}
								minimumTouchDrag={50}
							>
								{filteredAndSortedProducts
									.map((product, index) => (
										<ItemListHome product={product} index={index} storeid={storeid} />
									))}
							</Carousel>
							}
						</div>

					</div>
				</div>
			)}
		</div>
	);
}

export default ProductCarousel;