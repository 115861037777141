import { cloudflareLoader } from "/lib/services/helpers/cloudflareLoader";
import classes from './index.module.scss'

const LaunchStayTune = ({ isMobileType, image, mobileImage, isTimerAvailable, video, video_mob }) => {

    return (
        <div className={`${!isTimerAvailable ? classes.teaserWithoutTimerParent : classes.teaserBannerParent}`}>

            {image && mobileImage ? (
                <img className={classes.teaserBanner}
                    loading="eager"
                    src={cloudflareLoader({
                        src: isMobileType ? mobileImage : image,
                        width: isMobileType ? '992' : '1900',
                        quality: 80,
                    })}
                    alt="Launch Teaser" />
            ) :
                <video loop autoPlay muted playsInline>
                    <source src={isMobileType ? video_mob : video} type="video/mp4" />
                </video>}

        </div>
    )
}

export default LaunchStayTune;