import styles from '/components/Home/Home.module.css';
import Carousel from "react-multi-carousel";
import { responsive } from '/lib/services/helpers/responsive';
import style from '/styles/multi-category-home-page.module.scss'
import classes from './index.module.scss';

const LaunchProductCollection = (props) => {

    return (
        <div className={`teaser_container ${style.categorycontainer} ${classes.teaserProductParent}`}>
            {!props?.isMobileType ? (
                <div className={`${styles.sliderContainer} ${classes.carouselHeader}`}>
                    <Carousel
                        className={classes.categoryList}
                        swipeable={true}
                        draggable={false}
                        showDots={false}
                        responsive={responsive}
                        ssr={true} // means to render carousel on server-side.
                        // infinite={true}
                        autoPlaySpeed={3000}
                        keyBoardControl={true}
                        partialVisible={true}
                        removeArrowOnDeviceType={["tablet", "mobile"]}
                        additionalTransfrom={-15}
                        itemClass={styles.carousel_item_padding}
                        minimumTouchDrag={50}
                    >
                        {props.productsArr?.map?.((item, index) => (
                            <div key={index}>
                                {item?.image ?
                                    <img src={item?.image} loading='lazy' alt={item.alt} title={item.alt} placeholder="blur" />
                                    : <video loop autoPlay muted playsInline>
                                        <source src={item?.video_url} type="video/mp4" />
                                    </video>
                                }
                                <div className={classes.textParent}>
                                    <div>{item.text}</div>
                                    <div>{item.subtext}</div>
                                </div>
                            </div>
                        ))}
                    </Carousel>
                </div>
            ) : (
                <div className={`row ${classes.row}`}>
                    {props.productsArr?.map?.((item, index) => (
                        <div key={index} className={`col-5 ${classes["col-9"]}`}>
                            {item?.image ? <img src={item?.image} loading='lazy' alt={item?.alt} title={item?.alt} placeholder="blur" />
                                : <video loop autoPlay muted playsInline>
                                    <source src={item?.video_url} type="video/mp4" />
                                </video>
                            }
                            <div className={classes.textParent}>
                                <div>{item.text}</div>
                                <div>{item.subtext}</div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}

export default LaunchProductCollection;